<template>
  <div class="main-view">
    <div class="main-view__content">
      <div class="container-title py-3 px-4 px-sm-6 px-md-10 heading">
        <h2 class="text-uppercase font-weight-medium primary--text d-flex align-center">
          <v-skeleton-loader v-if="!title" type="heading" width="27rem"/>
          <template v-else>
            <span v-text="title"/>
            <v-fab-transition>
              <v-btn v-if="backTo" class="mx-4" fab large :to="backTo" exact-path>
                <v-icon dark>mdi-keyboard-return</v-icon>
              </v-btn>
            </v-fab-transition>
            <v-fab-transition>
              <v-btn v-if="$route.params.back" class="mx-4" fab large @click="$router.go(-1)">
                <v-icon dark>mdi-keyboard-return</v-icon>
              </v-btn>
            </v-fab-transition>
          </template>
        </h2>
        <p v-if="hint" class="pt-6">
          {{ hint }}
        </p>
      </div>
      <v-card flat class="py-4 py-sm-6 mx-4 mx-sm-6" transparent>
        <slot/>
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  name: "Container",
  props: {
    title: { type: String, default: '' },
    hint:  { type: String, default: '' },
    backTo: { type: Object, default: null },
  },
}
</script>

<style lang="scss">
.container-title {
  letter-spacing: 0.1rem;
}

.container-title .v-skeleton-loader__heading {
  height: 1.75rem;
  margin-top: 0.05rem;
  margin-bottom: 0.45rem;
}

.container-title h2 {
  min-height: 4rem;
}
</style>
